import {checkoutPayMutation, completeCheckoutMutation, getCheckout} from "@/plugins/API/checkout"
import {TRACKERS} from "@/constants/events";
import {track} from "@/composables/useTrackEvent";
import {useAddressStore} from "~/store/address";

export const useCheckoutStore = defineStore('checkout', () => {

    const addressStore = useAddressStore()
    const {addressList} = storeToRefs(addressStore)

    const selectedAddressId = ref(null)
    const setSelectedAddressId = (id) => {
        selectedAddressId.value = id
    }
    const selectedAddress = computed(() => addressList.value.find(item => item.address_id === selectedAddressId.value))

    const data = ref({
        address: {},
        payment: {}
    })

    const cart = computed(() => data.value?.cart || {})

    const firstTime = ref(true)
    const cardDetails = ref(null)
    const cvv = ref('')

    const selectedPayment = ref(null)
    const tamaraPaymentType = ref('')
    const rewardOption = ref('')

    const {isLoading: getCheckoutLoading, mutateAsync: getCheckoutMutateAsync} = getCheckout()
    const triggerGetCheckout = () => {
        getCheckoutMutateAsync({
            payment_method: selectedPayment.value,
            first_time: firstTime.value,
            cardDetails: cardDetails.value,
            reward_option: rewardOption.value?.code || ''
        }).then(({data: checkoutData}) => {
            data.value = checkoutData
            selectedPayment.value = checkoutData?.payment?.current
            if (firstTime.value)
                track(TRACKERS.OPEN_CHECKOUT_PAGE, firstTime.value)
            firstTime.value = false
        })
    }

    const {isLoading: payCheckoutLoading, mutateAsync: payCheckoutMutateAsync} = checkoutPayMutation()
    const checkoutPay = () => {
        payCheckoutMutateAsync({...cardDetails.value, cvv: cvv.value}).then(({data}) => {
            if (data.payment_response._links.redirect.href) {
                window.location =
                    data.payment_response._links.redirect.href
            }
        })
    }

    const {isLoading: completeLoading, mutateAsync: completeMutateAsync} = completeCheckoutMutation()
    const completeCheckout = () => {
        completeMutateAsync({payment_method: selectedPayment.value}).then(({data}) => {
            const router = useRouter()
            router.push(useNuxtApp().$routerUrl(`/success/${data.order_id}`))
        })
    }

    const loading = computed(() => getCheckoutLoading.value || payCheckoutLoading.value || completeLoading.value || false)

    const $reset = () => {
        cardDetails.value = null
        selectedPayment.value = null
        tamaraPaymentType.value = ""
        rewardOption.value = ""
        firstTime.value = true
        cvv.value = ''
    }

    return {
        data,
        cart,

        loading,
        firstTime,
        cardDetails,
        cvv,

        triggerGetCheckout,
        checkoutPay,
        completeCheckout,

        selectedAddressId,
        setSelectedAddressId,
        selectedAddress,

        selectedPayment,
        tamaraPaymentType,
        rewardOption,

        $reset
    }
})